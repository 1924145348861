<template>
  <div class="lookupdetail">
      <div class="lookup" v-if="isAdmin">
          <form  ref="lookupform" v-on:keyup.enter="LookupWallets">
              <div class="vertFlexLeft">
                  <div class="lookupHeader">
                      <label class="titlelabel bottom10">{{lookupPromptText}}</label>
                      <linkbutton toplabel="Clear" @click="clearLookup()" class="clearlink"/>
                  </div>
                
     
                <!-- <entry-select :items="lookupTypeList" v-model="walletFilter.lookupType"/> -->

                
                <formentrystacked  label="First Name" v-model="walletFilter.lookupFirst" minlength="3" placeholder="minimum 3 characters" />
                <formentrystacked  label="Last Name" v-model="walletFilter.lookupLast" minlength="3" placeholder="minimum 3 characters"  />
                <formentrystacked  label="Email" v-model="walletFilter.lookupEmail"   inputtype="email"  />
                <formentrystacked  label="Badge" v-model="walletFilter.lookupBadge"    />
                <div class="horizFlex ">
                    <input class="entryCheckbox" type="checkbox" v-model="walletFilter.showInactiveWallets" >
                    <label for="" class="mediumtext">Show Inactive Wallets</label>
                </div>
                <div class="lookupHeader">
                    <newwallet @refresh="RefreshWallet"/>
                    <smallbutton @click="LookupWallets()" toplabel="Go"/>
                    
                    <!-- <smallbutton @click="addParent()" toplabel="Add Wallet" /> -->
                </div>
                
              </div>
              </form>

              <div v-if="pleaseWait">
                  <label for="" class="pleasewaitlabel">{{pleaseWaitText}}</label>
              </div> 
              <div>
                <label class="responsemessage">{{responseMessage}}</label>
              </div>
               <div class="resultsdiv" v-if="lookupresults.length>0">
                <div v-for="result in lookupresults" :key="result.accountID" :value="result.accountID" >
                    <lookupresult :topLeft="buildResultName(result)"  :bottomLeft="result.accountName"    @click="selectWallet(result)"
                        :selected="result.ID==selectedResultID" :inactive="!result.IsActive">
                   
                    </lookupresult>  
                </div>
              </div>
            

            

      </div>

      <div :class="walletclass">
          <div class="horizFlexStretch">
            <label class="titlelabel">Wallet (Payer)</label>
            <div class="refresh">
                <linkbutton @click="RefreshWallet" toplabel="Refresh"/>
                <p class="userlabel">{{lastRefreshDateTime_Wallet}}</p>
            </div>
            
          </div>
          <div v-if="currentWallet!=null">
            <wallet @refresh="RefreshWallet" ref="mywallet"/>

            <div class="horizFlexStretch">
                <div class="vertFlexLeft">
                    <label  class="titlelabel">Accounts (Student/Staff)</label>
          
                    <div class="horizFlexLeft margintop" v-if="isAdmin">
                        <input class="entryCheckbox" type="checkbox" v-model="showInactiveAccounts" >
                        <label for="" class="mediumtext">Show Inactive Accounts</label>
                    </div>
                </div>
                    
               
              
              <div class="horizFlex">
                  <newstudent :walletId="currentWallet.ID" @refresh="RefreshWallet" v-if="isAdmin"/>
                  <mergeaccounts @refresh="RefreshWallet" v-if="multipleAccountActions"/>
                  <movefunds @refresh="RefreshWallet" v-if="multipleAccountActions"/>
 
              </div>
            </div>  

            <div v-for="account in currentWallet.accountList" :key="account.id" :value="account.id" >
                <accountview :account="account" @refresh="RefreshWallet" @gettrans="gettransactions" v-if="showInactiveAccounts || account.IsActive"/>
            </div>  

          </div>
      </div>
      <GlobalEvents v-if="listenersConnected" target="window" @focus="RefreshCurrentWallet"/> 
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../datamethods.js'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import formentrystacked from '../controls/entry-stacked.vue'
import entrySelect from '../controls/entry-select'
import lookupresult from '../controls/lookupresult.vue'
import linkbutton from '../controls/linkbutton'
import wallet from './wallet.vue'
import accountview from './account.vue'
import newwallet from '../actions/newwallet.vue'
import newstudent from '../actions/newstudent.vue'
import mergeaccounts from '../actions/mergeaccounts.vue'
import movefunds from '../actions/movefunds.vue'

export default {
    name:'lookupviewwallet',
    mixins:[datamethods, format],
    components:{
        smallbutton,
        formentrystacked,
        entrySelect,
        lookupresult,
        linkbutton,
        wallet,
        accountview,
        newwallet,
        newstudent,
        mergeaccounts,
        movefunds
 
    },
    computed:{
        ...mapFields([
            'lookupresults',
            'currentWallet',
            'loggedInUser.isAdmin',
            'walletFilter',
            'pleaseWait',
            'pleaseWaitText',
            'lastRefreshDateTime_Wallet',
            'disableAddFunds',
            'showAddFunds',
            'imagepath'
        ]),
        walletclass(){
            if(this.isAdmin){return 'wallet'}
            else{return 'walletfull'}
        },
        multipleAccountActions(){
            return this.isAdmin && this.currentWallet.accountList.length>1
        },
    },
    mounted(){
        this.determineDisableAddFunds()
        this.getimagepath()
    },
    data(){
        return{
            lookupTypeList:[
                {id:'1',name:"Lookup By Parent/Wallet"},
                {id:'2',name:"Lookup By Student/Account"}
            ],
            
            lookupPromptText:"Lookup",
            responseMessage:'',
            showInactiveAccounts:false,
            selectedResultID:0,
            listenersConnected:true
        }
    },
    methods:{
        clearLookup(){
            this.$store.dispatch('clear_walletfilter');     
        },
        buildResultName(result){
            var name = result.Name
            if (!result.IsActive){
                name = name + ' INACTIVE'
            }
            return name
        },
        async gettransactions(filter){
            //find the correct account
            var selacct
            this.currentWallet.accountList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let acct=arr[index]
                if(acct.ID==filter.accountId){
                    selacct=acct
                }
            } 

            let req = filter

            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'wallettransactionsfiltered')
            //handle response
            if(response.Successful){
                 selacct.transactionList =response.AccountTransactionList;
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getimagepath(){

            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'documentimagepath')
            //handle response
            if(response.Successful){
                 this.imagepath= response.StringResponse
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;  
        },
        selectWallet(result){
            this.selectedResultID=result.ID
            this.getWallet(result.ID)
        },
        RefreshWallet(walletid){
            console.log('refresh ' + walletid)
            if(walletid==undefined){walletid=this.currentWallet.ID}
            this.getWallet(walletid)
        },
        RefreshCurrentWallet(){
            this.RefreshWallet(this.currentWallet.ID)
        },
        determineDisableAddFunds(){
            var disable = false
            var allowAdminAddFunds = true
            var allowUserAddFunds=true
            var showUserAddFunds = true

            if(window.ADMIN_ADDFUNDS=="False"){allowAdminAddFunds=false}
            if(window.USER_ADDFUNDS=="False"){allowUserAddFunds=false}
            if(window.SHOW_USER_ADD_FUNDS=="False"){showUserAddFunds=false}

            if(this.isAdmin && this.isAdminWallet){ //admin, their OWN wallet - treat as user
                disable= !allowUserAddFunds 
            }else if(this.isAdmin){ //admin, other wallets - treat as admin
                disable= !allowAdminAddFunds
            }else{ //user
                disable= !allowUserAddFunds
            }
            this.disableAddFunds=disable;

            //show
            if(!this.isAdmin && !showUserAddFunds ){
                this.showAddFunds=false
            }else{this.showAddFunds=true}

        },
        async LookupWallets(){
            this.responseMessage=""
            this.lookupresults=[]
            //validate
            let req = {
                lookup:{
                    FirstName:this.walletFilter.lookupFirst,
                    LastName:this.walletFilter.lookupLast,
                    EmailAddress:this.walletFilter.lookupEmail,
                    BadgeNumber:this.walletFilter.lookupBadge,
                    SearchAccount:this.walletFilter.lookupType=="2",
                    SearchWallet:this.walletFilter.lookupType=="1",
                    restrictToIsActive:this.walletFilter.showInactiveWallets==false
                }
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'walletlookup')
            //handle response
            if(response.Successful){
                 this.lookupresults =response.ResultList;
                 this.$store.dispatch('setCurrentDateTime_Wallet');
                if(this.lookupresults.length==0){this.responseMessage="No Wallets or Accounts found. Please try again."}
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;    
           
        },
        clearselect(){
            this.currentWallet.accountList.forEach(myFunction2)

            function myFunction2(item, index, arr) {
                let acct=arr[index]
                acct.selected=false;
            } 
        },
        async getWallet(id){
            let prevwallet = this.currentWallet.ID
            console.log('old ' + prevwallet + ' new ' + id)
            let req = {
                wallet:{ID:id}
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'walletrefresh')
            //handle response
            if(response.Successful){
                 this.currentWallet =response.wallet;
                 this.$store.dispatch('setCurrentDateTime_Wallet');
                if(prevwallet!=this.currentWallet.ID){this.$refs.mywallet.responseMessage=''}
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;  

        },
        showMoveFunds(){
            return this.isAdmin && this.currentWallet.accountList.length>1
        },
        
    }
}
</script>

<style>
.lookupdetail{
     margin:0px;
    display:grid;
    grid-template-columns:1fr 4fr;
    grid-template-rows:auto;
    grid-template-areas: 
    "lookup detail"
}

.tokenname{
  margin:0px 10px 0px 0px ;
}
.note{
  word-wrap:break-word;
}
.resultsdiv{

    height:350px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.transdiv{
    height:250px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.wallet{
    padding-left:20px;
    
}
.walletfull{
    grid-column-start: span 2;
}
.refresh{
    display:flex;
    flex-direction: column;
    text-align: right;
    margin-right:20px;
}
.margintop{
    margin-top:5px;
}
.lookupHeader{
  display:flex;
  padding:0px;
  flex-direction: row;
  margin:0px 0px 0px 0px ;
  justify-content:space-between;
  width:100%;
}
.clearlink{
    margin-top:5px;
}
</style>